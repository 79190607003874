import { useEffect, useState } from "react";
import type { UploadFile } from "antd";
import { Upload, Popover } from "antd";
import { Cancel, AddCircle } from "@styled-icons/material";
import { v4 as uuidv4 } from 'uuid';
import UploadLinkForm from "./upload-link-form";
import {
  StyledUploadActionContainer,
  StyledPopover,
  StyledUploadButton,
  StyledBottomContainer,
  StyledAddFileContainer,
  StyledUploadedFile,
  StyledFileName,
  StyledAddResourceButton,
} from "./style";
import { ActionItemResource } from "../../types";
import { useQuery } from "@apollo/client";
import { GET_SC_DOWNLOAD_URL } from "@crimson-education/core-shared-graphql";
import { useApiClient } from '../../context';
export const download = (name: string, url: string) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

export const UploadFileSystem = ({
  resources,
  onChangeFileList,
  mode,
}: {
  resources?: ActionItemResource[] | undefined;
  onChangeFileList: (fileList: UploadFile[]) => void;
  mode?: 'show' | 'edit' | 'new';
})  => {
  const { coreApiClient } = useApiClient();
  const { refetch: getStudentCenterDownloadUrl } = useQuery(GET_SC_DOWNLOAD_URL, {
    client: coreApiClient,
    skip: true,
  });
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  useEffect(()=>{
    onChangeFileList(fileList);
  }, [fileList]);
  useEffect(() => {
    if (resources && resources.length > 0) {
      setFileList(resources.map((resource) => {
        return {
          uid: resource.id,
          name: resource.title,
          url: resource.url,
          type: resource.type,
          linkProps: resource.mediaType //put mediaType to linkProps since there is no other suitable attr.
        }
      }));
    } else {
      setFileList([]);
    }
  }, [resources])
  const props = {
    multiple: true,
    beforeUpload: (file: UploadFile) => {
      setFileList([...fileList, file]);
      return false;
    },
  };
  const deleteFile = (index: number) => {
    const newFileList = [
      ...fileList.slice(0, index),
      ...fileList.slice(index + 1),
    ];
    setFileList(newFileList);
  };

  const uploadLink = (resourceCaption: string, link: string) => {
    setUploadLinkOpen(false);
    const newFileList = [
      ...fileList,
      {
        name: resourceCaption,
        url: link,
        uid: uuidv4(),
        type: 'Link',
      },
    ];
    setFileList(newFileList);
  };
  const [uploadContainerOpen, setUploadContainerOpen] = useState(false);
  const [uploadLinkOpen, setUploadLinkOpen] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState<any>({});

  return (
    <StyledBottomContainer>
      <StyledAddFileContainer>
        {fileList.map((file, index) => {
          return (
            <div key={index}>
              {
                (file.type === 'Link') ? <StyledUploadedFile key={index}>
                  <img src="/static/online_doc.svg" style={{ width: '12px', marginRight: '4px' }} />
                      <StyledFileName href={file.url?.startsWith("http") ? file.url : 'https://' + file.url} target="__blank">{file.name}</StyledFileName>
                  {(mode === 'new' || mode === 'edit')
                    && <Cancel width="12px" color="#E3E7ED" cursor="pointer" onClick={() => {
                      deleteFile(index);
                    }} />
                  }

                </StyledUploadedFile> :
                  <StyledUploadedFile key={index}>
                    {
                      file.url ? <>
                      <img src="/static/pdf.svg" style={{ width: "16px", marginLeft: '-2px' }} />
                        <StyledFileName onClickCapture={(e) => {
                          if (downloadStatus[index] === 'downloading') return;
                          setDownloadStatus({ ...downloadStatus, [index]: 'downloading' });
                          e.stopPropagation();
                          if (file.status === 'uploading') return;
                          getStudentCenterDownloadUrl({ fileId: file.url }).then((res) => {
                            setDownloadStatus({ ...downloadStatus, [index]: 'downloaded' });
                            const { url } = res.data;
                            download(file.name, url);
                          });
                      }}>{downloadStatus[index] === 'downloading' && '(Downloading...)'} {file.name}</StyledFileName></>:
                      <><img src="/static/pdf.svg" style={{ width: "16px", marginLeft: '-2px' }} />
                        <StyledFileName>{file.name}</StyledFileName>
                      </>
                    }
                    {(mode === 'edit' || mode === 'new') && <Cancel width="12px" color="#E3E7ED" cursor="pointer" onClick={(e) => {
                        e.stopPropagation();
                        deleteFile(index);
                      }} />
                    }
                  </StyledUploadedFile>
              }
            </div>

          );
        })}
        {
          (mode === 'edit' || mode === 'new') && <StyledPopover
            trigger="click"
            open={uploadContainerOpen}
            onOpenChange={visible=>{
              setUploadContainerOpen(visible);
            }}
            content={
              <StyledUploadActionContainer>
                <Popover
                  open={uploadLinkOpen}
                  onOpenChange={visible => {
                    setUploadLinkOpen(visible);
                  }}
                  content={
                    <div style={{width: '360px'}}>
                    <UploadLinkForm uploadLink={uploadLink} />
                    </div>
                }
                  trigger="click"
                >
                  <StyledUploadButton onClick={()=>{
                    setUploadContainerOpen(false);
                  }}>Add a Link</StyledUploadButton>
                </Popover>
                <Upload
                  {...props}
                  fileList={fileList}
                  showUploadList={false}
                  accept="application/pdf"
                >
                  <StyledUploadButton>
                    Upload from local device
                  </StyledUploadButton>
                </Upload>
              </StyledUploadActionContainer>
            }
          >
            <StyledAddResourceButton>
              <AddCircle width="20px" color="#A9ACC0" /> Add a Resource
            </StyledAddResourceButton>
          </StyledPopover>
        }

      </StyledAddFileContainer>
    </StyledBottomContainer>
  )
}
