import { Input, Menu, MenuProps, Divider } from 'antd';
import { StyledPopover } from '../predefined-task-toggle/style';
import { StyledOperationButton } from '../action-items/style';
import { Row } from '../layouts/row';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { gql, useQuery } from '@apollo/client';
import { useApiClient } from '../../context';
import { Categories, Category2ColorMap } from '../../types';
import { StyledExistLabel, StyledNewLabel } from './style';
import { findCategoryBySubcategory } from './add-new-mission-modal';

const { Search } = Input;
export interface IMission {
  id: string;
  category: Categories;
  title: string;
  status: 'PLANNED' | 'IN_PROGRESS' | 'DONE';
  source?: string;
  selected?: boolean;
}

const StyledDivider = styled(Divider)`
  margin: 0;
`;

export const MenuList = styled.div<{ $selected: boolean }>`
  display: ${(props) => (props.$selected ? 'flex' : 'none')};
  width: 100%;
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none;
  }
  .ant-menu-vertical .ant-menu-item {
    margin: 0;
    padding: 0;
    padding-left: 10px !important;
    padding-right: 15px;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
`;
export const StyledLeftMenuItem = styled(Menu.Item) <{ $selected: boolean }>`
  height: auto!important;
  line-height: 20px!important;
  &.ant-menu-item {
    background-color: ${(props) => (props.$selected ? '#F3F3F7' : 'white')}!important;
    margin-bottom: 0 !important;
    margin-top: 0!important;
    padding: 10px 16px;
  }
  width: 100%;
  margin-bottom: 20px!important;
  .ant-menu-title-content {
    > div {
      width: 100%;
      word-wrap: break-word;
      white-space: normal;
    }
  }
`;
export const StyledMenuItem = styled(Menu.Item) <{ $selected: boolean }>`
  background-color: ${(props) => (props.$selected ? '#F3F3F7' : 'white')}!important;
  .ant-menu-title-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const StyledNoMissionFoundLine = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding: 10px 15px;
`;

export const StyledNoMissionText = styled.div`
  font-size: 12px;
  color: #828599;
`;

export const StyledAddMissionButton = styled.div`
  cursor: pointer;
  color: var(--color-indigo);
`

export const SEARCH_MISSIONS = gql`
  query SearchMissions($userId: String!, $keyword: String!) {
    searchMissions(userId: $userId, keyword: $keyword) {
      id
      category
      title
      status
    }
  }
`;

export const StyledRightMenu = styled.div`
  width: 100%;
`;

export const TagAMission = ({
  onSelectAMission,
  studentId,
  selectedMission,
  aiRecommendedMission,
  setAddNewMissionVisible,
  setTagAMissionOpen,
  setAddFromMissionLib,
  refetchMissionList,
  setRefetchMissionList
}: {
  onSelectAMission: (mission: any) => void;
  studentId: string;
  selectedMission: any;
  aiRecommendedMission: any;
  onAddNewMission: (mission: any) => void;
  setAddNewMissionVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setTagAMissionOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAddFromMissionLib: React.Dispatch<React.SetStateAction<boolean>>;
  refetchMissionList: boolean;
  setRefetchMissionList: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [debouncedSearchKeword, setDebouncedSearchKeyword] = useState<string>('');
  const [openKeys, setOpenKeys] = useState<string | undefined>();
  const [transformedMissions, setTransformedMissions] = useState<Record<string, IMission[]>>({});
  const { roadmapApiClient } = useApiClient();

  const { data: searchMissionsData, refetch: refetchSearchMissions } = useQuery<{
    searchMissions: any[];
  }>(SEARCH_MISSIONS, {
    client: roadmapApiClient,
    variables: {
      userId: studentId,
      keyword: debouncedSearchKeword,
    },
  });
  useEffect(() => {
    if (refetchMissionList) {
      refetchSearchMissions();
      setRefetchMissionList(false);
    }
  }, [refetchMissionList])

  useEffect(() => {
    if (searchMissionsData) {
      const newTransformedMissions = searchMissionsData.searchMissions.reduce((acc, curr) => {
        if (!acc[curr.category]) {
          acc[curr.category] = [];
        }
        acc[curr.category].push(curr);
        return acc;
      }, {} as Record<string, IMission[]>);

      // Handle AI recommended mission
      if (aiRecommendedMission) {
        const category = findCategoryBySubcategory(aiRecommendedMission.subcategory);
        if (category) {
          const updatedCategoryMissions = newTransformedMissions[category] || [];
          if (!aiRecommendedMission.id) {
            updatedCategoryMissions.push({ ...aiRecommendedMission, source: 'ai' });
          } else {
            const missionIndex = updatedCategoryMissions.findIndex((mission: any) => mission.id === aiRecommendedMission.id);
            if (missionIndex !== -1) {
              updatedCategoryMissions[missionIndex] = { ...updatedCategoryMissions[missionIndex], source: 'ai' };
            } else {
              updatedCategoryMissions.push({ ...aiRecommendedMission, source: 'ai' });
            }
          }
          newTransformedMissions[category] = updatedCategoryMissions;
        }
      }

      // Handle selected mission
      if (selectedMission) {
        const category = selectedMission.category || findCategoryBySubcategory(selectedMission.subcategory);
        if (category) {
          setOpenKeys(category);
          newTransformedMissions[category] = newTransformedMissions[category]?.map((mission: any) =>
            mission.id === selectedMission.id
              ? { ...mission, selected: true }
              : { ...mission, selected: false }
          );
        }
      }

      setTransformedMissions(newTransformedMissions);
    }
  }, [searchMissionsData, aiRecommendedMission, selectedMission]);


  const [searchKeyword, setSearchKeyword] = useState<string>('');

  const handleSearch = (newValue: string) => {
    setSearchKeyword(newValue);
  };
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchKeyword(searchKeyword);
    }, 1500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchKeyword]);
  const handleMenuClick: MenuProps['onClick'] = (e) => {
    const key = e.key;
    setOpenKeys(key);
  };

  const handleManualSelectMission = (mission: any) => {
    onSelectAMission(mission);
  };

  return (
    <div style={{ width: '468px' }}>
      <Row>
        <Menu style={{ height: '280px', width: '182px', overflow: 'auto' }} onClick={handleMenuClick}>
          {
            transformedMissions && Object.keys(transformedMissions).map((category) => {
              const categoryInfo =
                Category2ColorMap.find((item) => item.type === category) || Category2ColorMap[0];
              const categoryName = categoryInfo.category;
              return <StyledLeftMenuItem key={category} $selected={openKeys === category}>
                <div>{categoryName}</div>
              </StyledLeftMenuItem>
            })
          }
        </Menu>
        <StyledRightMenu>
          <Search onSearch={handleSearch} style={{ width: '100%', padding: '6px 20px' }} />
          {transformedMissions && Object.keys(transformedMissions).map((key) => {
            const missions = transformedMissions[key];
            return <MenuList $selected={openKeys === key}>
              <Menu
                style={{ width: '100%', overflow: 'auto', height: '256px' }}
                multiple
                expandIcon={<DownOutlined />}
              >
                {missions.map((mission) => (
                  <StyledMenuItem onClick={() => handleManualSelectMission(mission)} $selected={mission.id === selectedMission?.id}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {mission.source === 'ai' ? <img src="/static/ai_bot.png" style={{ width: '20px', marginRight: '10px' }} /> : <div style={{ width: '20px', marginRight: '10px' }}></div>}
                        <span style={{ maxWidth: '180px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{mission.title}</span>{
                          mission.source === 'ai' ?
                            <span>{mission.id ? <StyledExistLabel>EXISTING</StyledExistLabel> : <StyledNewLabel>NEW</StyledNewLabel>}</span>
                            : <span></span>
                        }
                      </div>
                      {mission.selected && <img src="/static/tick.svg" />}
                    </div>
                  </StyledMenuItem>
                ))}
              </Menu>
            </MenuList>
          })}
        </StyledRightMenu>
      </Row>
      <StyledDivider />
      <StyledNoMissionFoundLine>
        <StyledNoMissionText>No matching missions found?</StyledNoMissionText>
        <StyledPopover placement={'bottomRight'}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          getPopupContainer={(triggerNode: any) =>
            triggerNode.parentNode as HTMLElement
          }
          showArrow={false}
          content={
            <div style={{ color: '#73747D', textAlign: 'right', padding: '8px 14px' }}>
              <StyledOperationButton onClick={() => {
                setAddFromMissionLib(true);
                setTagAMissionOpen(false);
              }}>
                Add from Mission Library
              </StyledOperationButton>
              <StyledOperationButton onClick={() => {
                setAddNewMissionVisible(true);
                setTagAMissionOpen(false);
              }}>
                Create from Scratch
              </StyledOperationButton>
            </div>
          }
          trigger={'click'}
        >
          <StyledAddMissionButton>+ Add a New Mission</StyledAddMissionButton>
        </StyledPopover>
      </StyledNoMissionFoundLine>
    </div>
  )
}
