import { Menu, MenuProps, Input, Popover, Space } from "antd";
import { Row } from '../layouts/row';
import { StyledExistLabel, StyledTagAMissionButton, StyledNewLabel } from "./style";
import { IMission, MenuList, SEARCH_MISSIONS, StyledLeftMenuItem, StyledMenuItem, StyledRightMenu } from "./tag-a-mission";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { DownOutlined } from '@ant-design/icons';
import { useApiClient } from "../../context";
import { Category2ColorMap } from "../../types/mission";
const { Search } = Input;

type Props = {
  studentId?: string;
  onSelectAMission?: (mission: any) => void;
}
const SelectAMission = ({ studentId, onSelectAMission }: Props): JSX.Element => {
  const [debouncedSearchKeword, setDebouncedSearchKeyword] = useState<string>('');
  const [openKeys, setOpenKeys] = useState<string | undefined>();
  const { roadmapApiClient } = useApiClient();

  const { loading, error, data: searchMissionsData } = useQuery<{
    searchMissions: any[];
  }>(SEARCH_MISSIONS, {
    client: roadmapApiClient,
    variables: {
      userId: studentId,
      keyword: debouncedSearchKeword,
    },
  });
  const [selectedMission, setSelectedMission] = useState<any>();
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  let missionList: IMission[] | undefined;
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchKeyword(searchKeyword);
    }, 1500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchKeyword]);
  if (!searchMissionsData) {
    missionList = undefined;
  } else {
    missionList = searchMissionsData.searchMissions;
  }
  // if selectedMission's source from ai and the status is new. then I should add this mission to the category.
  const transformedMissions = missionList?.reduce((acc, curr) => {
    if (!acc[curr.category]) {
      acc[curr.category] = [];
    }
    acc[curr.category].push(curr);
    return acc;
  }, {} as Record<string, IMission[]>) ?? {};;

  const handleSearch = (newValue: string) => {
    setSearchKeyword(newValue);
  };
  const handleMenuClick: MenuProps['onClick'] = (e) => {
    const key = e.key;
    setOpenKeys(key);
  };
  const handleManualSelectMission = (mission: any) => {
    onSelectAMission?.(mission);
    setSelectedMission(mission);
  };
  return <div style={{ width: '468px' }}>
    <Row>
      <Menu style={{ height: '280px', width: '182px', overflow: 'auto' }} onClick={handleMenuClick}>
        {
          transformedMissions && Object.keys(transformedMissions).map((category) => {
            const categoryInfo =
              Category2ColorMap.find((item) => item.type === category) || Category2ColorMap[0];
            const categoryName = categoryInfo.category;
            return <StyledLeftMenuItem key={category} $selected={openKeys === category}>
              <div>{categoryName}</div>
            </StyledLeftMenuItem>
          })
        }
      </Menu>
      <StyledRightMenu>
        <Search onSearch={handleSearch} style={{ width: '100%', padding: '6px 20px' }} />
        {transformedMissions && Object.keys(transformedMissions).map((key) => {
          const missions = transformedMissions[key];
          return <MenuList $selected={openKeys === key}>
            <Menu
              style={{ width: '100%', overflow: 'auto', height: '256px' }}
              multiple
              expandIcon={<DownOutlined />}
            >
              {missions.map((mission) => (
                <StyledMenuItem onClick={() => handleManualSelectMission(mission)} $selected={mission.id === selectedMission?.id}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {mission.source === 'ai' ? <img src="/static/ai_bot.png" style={{ width: '20px', marginRight: '10px' }} /> : <div style={{ width: '20px', marginRight: '10px' }}></div>}
                      <span style={{ maxWidth: '180px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{mission.title}</span>{
                        mission.source === 'ai' ?
                          <span>{mission.id ? <StyledExistLabel>EXISTING</StyledExistLabel> : <StyledNewLabel>NEW</StyledNewLabel>}</span>
                          : <span></span>
                      }
                    </div>
                    {mission.id === selectedMission?.id && <img src="/static/tick.svg" />}
                  </div>
                </StyledMenuItem>
              ))}
            </Menu>
          </MenuList>
        })}
      </StyledRightMenu>
    </Row>
  </div>
}

const SelectMissionInput = ({ studentId, onSelectAMission, selectedMission }: {
  studentId?: string;
  onSelectAMission?: (mission: any) => void;
  selectedMission: any;
}) => {
  const [tagAMissionOpen, setTagAMissionOpen] = useState(false);

  return <Popover
    trigger="click"
    showArrow={false}
    overlayClassName="tag-a-mission-popover"
    placement="bottomLeft"
    open={tagAMissionOpen}
    content={<SelectAMission studentId={studentId} onSelectAMission={(mission: any) => {
      onSelectAMission?.(mission);
      setTagAMissionOpen(false);
    }}
    />}
    getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
    onOpenChange={(visible) => {
      setTagAMissionOpen(visible)
    }}
    overlayInnerStyle={{
      borderRadius: 8,
      border: '1px solid #e3e7ed',
      boxShadow: '0px 5px 15px rgba(61, 64, 144, 0.2)',
    }}
    overlayStyle={{ padding: 0, borderRadius: 8, border: '1px solid #e3e7ed' }}
  >
    <StyledTagAMissionButton onClick={() => {
      setTagAMissionOpen(!tagAMissionOpen);
    }}>
      <Space style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        {selectedMission ? <span>{selectedMission.title}</span> : <span>Tag a Mission</span>}
        <DownOutlined style={{ display: 'flex', alignItems: 'center' }} />
      </Space>
    </StyledTagAMissionButton>
  </Popover>
}
export default SelectMissionInput;
