import { Modal, DatePicker, notification } from 'antd';
import { Today } from '@styled-icons/material';
import { useState } from 'react';
import {
  ADD_ACTION_ITEMS,
  ADD_OR_UPDATE_EXTRACURRICULAR,
  ADD_READING_TO_MISSION,
  EDIT_MISSION,
} from '@crimson-education/core-shared-graphql';
import { useMutation } from '@apollo/client';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Container,
  StyledAddTaskButtonContainer,
  StyledSectionTitle,
  StyledTaskContainer,
  Title,
} from '../task-library/style';
import {
  MissionDetailObjectType,
  ActionItem as ActionItemType,
} from '../../types';
import {
  MissionListContainer,
  StyledMissionListHeader,
  StyledNote,
} from './styles';
import { useApiClient } from '../../context';
import { CancelButton, SubmitButton } from '../remove-button/style';
const { RangePicker } = DatePicker;

export const AddMissionModal = ({
  addMissionOpen,
  closeMissionModal,
  tasks,
  roadmapId,
  missionId,
  userId,
  onAddMissionSuccess,
  category,
  subCategory,
  missionTitle,
  missionDesc,
}: {
  addMissionOpen: boolean;
  closeMissionModal: () => void;
  tasks: ActionItemType[];
  roadmapId: string;
  missionId: string | undefined;
  userId: string;
  onAddMissionSuccess: () => void;
  category: string;
  subCategory: string;
  missionTitle: string | undefined;
  missionDesc: string | undefined;
}) => {
  const { roadmapApiClient, studentCenterApiClient } = useApiClient();
  const [addActionItems] = useMutation(ADD_ACTION_ITEMS, {
    client: roadmapApiClient,
    fetchPolicy: 'network-only',
  });
  const [editMission] = useMutation(EDIT_MISSION, {
    client: roadmapApiClient,
    ignoreResults: true,
  });
  const [addReading] = useMutation(ADD_READING_TO_MISSION, {
    client: studentCenterApiClient,
    ignoreResults: true,
  });
  const [addOrUpdateExtracurricular] = useMutation(
    ADD_OR_UPDATE_EXTRACURRICULAR,
    {
      client: studentCenterApiClient,
      ignoreResults: true,
    },
  );
  const [dateParams, setDateParams] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: '',
    endDate: '',
  });
  const [submitLoading, setSubmitLoading] = useState(false);
  const confirmAddMission = async () => {
    const inputVariables: any = {
      action: 'add',
      category: category,
      subcategory: subCategory,
      title: missionTitle,
      description: missionDesc,
      integrationSource: 'missionLibrary',
      linkId: missionId,
    };

    if (dateParams.startDate && dateParams.endDate) {
      inputVariables['startAt'] = dateParams.startDate;
      inputVariables['dueDate'] = dateParams.endDate;
    }
    const missions = [inputVariables];
    const { data } = await editMission({
      variables: {
        input: { roadmapId, userId, missions },
      },
    });
    const id =
      data?.editMissions && data?.editMissions?.length > 0
        ? data?.editMissions[0].id
        : '';
    const isCapstone =
      missionTitle?.startsWith('Crimson Capstone: ') &&
      missionTitle !== 'Crimson Capstone: General' &&
      category === 'ACTIVITIES' &&
      subCategory === 'Extracurricular Activities';
    if (
      id &&
      missionTitle &&
      category === 'PERSONAL_DEVELOPMENT' &&
      subCategory === 'Readings'
    ) {
      const [title, author] = missionTitle.split(', ');
      await addReading({
        variables: {
          input: {
            missionId: id,
            userId,
            title,
            author,
            description: missionDesc,
            startAt: dateParams.startDate,
            dueDate: dateParams.endDate,
          },
        },
      });
    } else if (id && isCapstone) {
      await addOrUpdateExtracurricular({
        variables: {
          input: {
            missionId: id,
            userId,
            programProvider: 'CRIMSON_CAPSTONE',
          },
        },
      });
    }
    if (!isCapstone && tasks && tasks.length > 0) {
      const input = tasks.map((task) => {
        const inputVariables: any = {
          description: task.description,
          missionId: id,
        };
        if (dateParams.endDate) {
          inputVariables['dueDate'] = dateParams.endDate;
        }
        if (task.content) {
          inputVariables['content'] = task.content;
        }

        if (task.resources) {
          inputVariables['resources'] = task.resources.map((resource) => {
            return {
              title: resource.title,
              url: resource.url,
              mediaType: resource.mediaType,
              orderIndex: resource.orderIndex,
              type: resource.type,
            };
          });
        }
        return inputVariables;
      });

      await addActionItems({
        variables: {
          input,
        },
      });
    }
    notification.success({
      message: 'Great!',
      description:
        'Mission successfully added to Student Center from the mission library!',
    });
    closeMissionModal();
    setSubmitLoading(false);
    onAddMissionSuccess();
  };
  return (
    <Modal
      visible={addMissionOpen}
      onCancel={closeMissionModal}
      footer={null}
      width={500}
      bodyStyle={{ padding: 0, display: 'flex' }}
    >
      <Container data-role="add-mission-modal-container">
        <Title>{'Add Mission'}</Title>
        <MissionListContainer>
          <div>
            <StyledMissionListHeader>Mission</StyledMissionListHeader>
            <div>{missionTitle}</div>
          </div>
          {tasks && tasks.length > 0 && (
            <div>
              <StyledMissionListHeader>Tasks</StyledMissionListHeader>
              <ul>
                {tasks?.map((task, index) => {
                  return <li key={index}>· {task.description}</li>;
                })}
              </ul>
            </div>
          )}
        </MissionListContainer>
        <StyledTaskContainer>
          <StyledSectionTitle>Timeline</StyledSectionTitle>
          <RangePicker
            style={{ width: '100%' }}
            allowClear={false}
            onChange={(dates) => {
              setDateParams({
                startDate: `${dates?.[0]?.toISOString()}`,
                endDate: `${dates?.[1]?.toISOString()}`,
              });
            }}
            suffixIcon={
              <Today
                style={{ height: '20px', color: 'var(--color-dark-navy)' }}
              />
            }
          />
        </StyledTaskContainer>
        <StyledNote>
          <InfoCircleOutlined style={{ width: '16px', marginRight: '8px' }} />
          <span>
            By default, the task due date matches the mission due date. Please
            edit them individually if necessary.
          </span>
        </StyledNote>
        <StyledAddTaskButtonContainer>
          <CancelButton onClick={closeMissionModal}>Cancel</CancelButton>
          <SubmitButton
            data-role={'confirm-submit-mission'}
            loading={submitLoading}
            disabled={submitLoading}
            onClick={() => {
              setSubmitLoading(true);
              confirmAddMission();
            }}
          >
            Confirm
          </SubmitButton>
        </StyledAddTaskButtonContainer>
      </Container>
    </Modal>
  );
};
