import styled from 'styled-components';
import type { HTMLAttributes, CSSProperties } from 'react';
import { omitNullValues } from './util';
import { useMemo } from 'react';

export type RowProps = {
  gap?: number | string;
  xcenter?: true; // horizontal center
  ycenter?: true; // vertical center
} & HTMLAttributes<HTMLDivElement> &
  Pick<
    CSSProperties,
    | 'alignItems'
    | 'justifyContent'
    | 'padding'
    | 'paddingLeft'
    | 'paddingTop'
    | 'paddingRight'
    | 'paddingBottom'
    | 'margin'
    | 'marginTop'
    | 'marginLeft'
    | 'marginBottom'
    | 'marginRight'
  >;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export /* @__PURE__*/ const Row = ({
  children,
  gap,
  alignItems,
  justifyContent,
  xcenter,
  ycenter,
  style,
  padding,
  paddingLeft,
  paddingTop,
  paddingRight,
  paddingBottom,
  margin,
  marginTop,
  marginLeft,
  marginBottom,
  marginRight,
  ...restProps
}: RowProps) => {
  const _style: React.CSSProperties = {
    gap,
    alignItems: ycenter ? 'center' : alignItems,
    justifyContent: xcenter ? 'center' : justifyContent,
  };
  const _paddingMargins = useMemo(() => {
    return omitNullValues({
      padding,
      paddingLeft,
      paddingTop,
      paddingRight,
      paddingBottom,
      margin,
      marginTop,
      marginLeft,
      marginBottom,
      marginRight,
    });
  }, [
    padding,
    paddingLeft,
    paddingTop,
    paddingRight,
    paddingBottom,
    margin,
    marginTop,
    marginLeft,
    marginBottom,
    marginRight,
  ]);
  return (
    <StyledRow
      style={{
        ..._paddingMargins,
        ..._style,
        ...style,
      }}
      {...restProps}
    >
      {children}
    </StyledRow>
  );
};
