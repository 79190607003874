import { RangeValue } from 'rc-picker/lib/interface';
import moment from 'moment';
import { Categories, MissionCategorySubcategoryMapping, Subcategories } from '../../types';
import { StyledMissionTitleInput, StyledCategorySelectContainer, StyledSelect, StyledButtonArea, StyledAddNewMissionTitle, StyledAddNewMissionDetail, StyledAddNewMissionContainer, StyledRangePicker, StyledDisplayMissionTitle, StyledNewLabel, StyledLinearGradientLine } from './style';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CancelButton, SubmitButton } from '../remove-button/style';
import { EDIT_MISSION } from '@crimson-education/core-shared-graphql';
import { useApiClient } from '../../context';
import { notification } from 'antd';

type Props = {
  setAddNewMissionVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onAddNewMission: (mission: any) => void;
  roadmapId: string;
  studentId: string;
}
export const findCategoryBySubcategory = (subcategory: Subcategories): string | undefined => {
  for (const [category, subcategories] of Object.entries(MissionCategorySubcategoryMapping)) {
    if (subcategories.includes(subcategory)) {
      return category;
    }
  }
  return undefined; // Return undefined if no match is found
};
const AddNewMissionModal = ({ setAddNewMissionVisible, onAddNewMission, roadmapId, studentId }: Props): JSX.Element => {
  const { roadmapApiClient } = useApiClient();
  const [title, setTitle] = useState('');
  const [subcategory, setSubcategory] = useState<Subcategories | undefined>();
  const [startAt, setStartAt] = useState<moment.Moment | null>();
  const [dueDate, setDueDate] = useState<moment.Moment | null>();
  const [editMission] = useMutation(EDIT_MISSION, {
    client: roadmapApiClient,
  });
  const onDateChange = async (dates: RangeValue<moment.Moment>) => {
    if (dates && dates.length > 1) {
      setStartAt(dates[0]);
      setDueDate(dates[1]);
    }
  };

  const AddMission = async () => {
    const category = subcategory ? findCategoryBySubcategory(subcategory) : '';
    if (!category) {
      notification.error({
        message: 'The category of the mission cannot be found.'
      })
      return;
    }
    let secondaryCategory;
    if (subcategory === Subcategories.University_Programs) {
      secondaryCategory = Categories.ACTIVITIES;
    }
    const inputVariable: any = {
      action: 'add',
      category,
      subcategory,
      title,
    };
    if (secondaryCategory) {
      inputVariable['secondaryCategory'] = secondaryCategory;
    };

    if (startAt) {
      inputVariable['startAt'] = startAt;
    };
    if (dueDate) {
      inputVariable['dueDate'] = dueDate;
    }
    const missions = [
      inputVariable,
    ];

    const { data } = await editMission({
      variables: {
        input: { roadmapId, userId: studentId, missions },
      },
    });
    const { editMissions } = data;
    const id = editMissions[0].id;
    setAddNewMissionVisible(false);
    onAddNewMission({
      title,
      id,
      category,
      subcategory,
    });
  }
  const canSubmit = title !== '' && subcategory;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isEditingMissionTitle, setIsEditingMissionTitle] = useState(false);

  return <StyledAddNewMissionContainer>
    <StyledLinearGradientLine/>
    <div style={{ padding: '30px' }}>
      <StyledAddNewMissionTitle>Add a New Mission</StyledAddNewMissionTitle>
      <StyledAddNewMissionDetail>
        <div>
          {
            isEditingMissionTitle ? <StyledMissionTitleInput placeholder="Add a Mission Title" value={title} onChange={(e) => {
              setTitle(e.target.value);
            }} bordered={false} /> :
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {title ? <StyledDisplayMissionTitle onClick={() => setIsEditingMissionTitle(true)}>{title}</StyledDisplayMissionTitle> : <div onClick={() => setIsEditingMissionTitle(true)} style={{ color: '#73747D', fontWeight: 600 }}>Add a mission title</div>}
                <StyledNewLabel>NEW</StyledNewLabel>
              </div>
          }
        </div>
        <StyledCategorySelectContainer>
          <StyledSelect
            placeholder="Select category"
            onChange={(v: any) => {
              setSubcategory(v);
            }}
            bordered={false}
            value={subcategory}
            dropdownStyle={{ minWidth: 200 }}
            options={Object.values(Subcategories).map((v) => {
              return {
                label: v,
                value: v
              }
            })}>
          </StyledSelect>
          <StyledRangePicker
            format="MMM DD, YYYY"
            separator={'-'}
            bordered={false}
            suffixIcon={null}
            onChange={onDateChange}
            getPopupContainer={(target) => target.parentElement as HTMLElement}
            value={[
              startAt
                ? moment(startAt)
                : null,
              dueDate
                ? moment(dueDate)
                : null,
            ]}
          />
        </StyledCategorySelectContainer>
      </StyledAddNewMissionDetail>
      <StyledButtonArea>
        <CancelButton style={{ width: '160px' }} onClick={() => { setAddNewMissionVisible(false) }}>Cancel</CancelButton>
        <SubmitButton style={{ width: '160px' }} disabled={!canSubmit || submitLoading} loading={submitLoading}
          onClick={async () => {
            setSubmitLoading(true);
            AddMission();
          }}>Add & Assign</SubmitButton>
      </StyledButtonArea>
    </div>

  </StyledAddNewMissionContainer>
}
export default AddNewMissionModal;
